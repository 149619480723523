var site = site || {};

site.quickshop = site.quickshop || {};
site.product = site.product || {};
site.product.view = site.product.view || {};
(function ($) {
  Drupal.behaviors.tabbedProductsBlockV1 = {
    attach: function (context) {
      var $modules = $('.js-tabbed-products-block-v1', context);
      var productsArray = page_data['custom-mpp'].products || [];
      var $tabsContainer = $('.js-tabbed-products-block__tabs', context);
      var colorTheme = $modules.data('color-theme');
      var ctaFontOverride = $modules.data('cta-font-override');

      if ($tabsContainer.find('.js-tabbed-products-block-trigger').length === 0) {
        $tabsContainer.addClass('hidden');
      }

      $modules.each(function () {
        var $module = $(this);
        var $grid = $('.js-product-grid', $module);
        var $carousels = $('.js-product-brief-carousel-items', $grid);
        var $trigger = $('.js-tabbed-products-block-trigger', $module);
        var $content = $('.js-tabbed-products-block-content', $module);
        var $products = $('.js-product-brief', $content);

        $trigger.off('click.tabbedBlock').on('click.tabbedBlock', function (event) {
          event.preventDefault();
          var index = $(this).index();

          switchToIndex(index);
        });

        function switchToIndex(index) {
          var $activeContent = $content.eq(index);

          // Show this tab content
          $content.removeClass('current');
          $activeContent.addClass('current');
          $trigger.removeClass('current');
          $trigger.eq(index).addClass('current');
        }

        $carousels.each(function () {
          var $this = $(this);
          var $arrowsDiv = $this.parent().find('.carousel-controls');
          var slidesToShow = JSON.parse(
            $this.closest('.js-product-grid').attr('data-grid-items-per-row')
          );
          var starColor = $this.data('star-color');
          var settings = {
            appendArrows: $arrowsDiv,
            infinite: true,
            slidesToShow: slidesToShow.large,
            slidesToScroll: slidesToShow.large,
            responsive: [
              {
                breakpoint: 1280,
                settings: {
                  rtl: site.direction.isRTL,
                  arrows: true,
                  dots: false,
                  slidesToShow: slidesToShow.medium,
                  slidesToScroll: slidesToShow.medium
                }
              },
              {
                breakpoint: 768,
                settings: {
                  rtl: site.direction.isRTL,
                  arrows: true,
                  dots: false,
                  slidesToShow: slidesToShow.small,
                  slidesToScroll: slidesToShow.small
                }
              }
            ],
            onInit: function (_slick) {
              _slick.$list
                .on(
                  'touchend.customSlick touchcancel.customSlick',
                  _.debounce(function () {
                    $(this).removeClass('swiping');
                  }, 100)
                )
                .on(
                  'touchstart.customSlick',
                  _.debounce(function () {
                    $(this).addClass('swiping');
                  }, 100)
                );
            }
          };

          function setStarColor() {
            if (starColor === 'star-grey') {
              var $stars = $this.find('.product_brief__stars-rating');

              $stars.css(
                'background-image',
                'url(/media/images/background-images/background-image36.png)'
              );
            }
          }
          // Init this carousel with our settings
          $this.slick(settings);
          setStarColor();
        });

        $products.each(function () {
          var $product = $(this);
          var productId = $product.data('product-id');
          var productData = _.find(productsArray, function (p) {
            return p.PRODUCT_ID === productId;
          });
          var _qsInit = function () {
            var isMobile =
              parseInt(Unison.fetch.now().width) === parseInt(Unison.fetch.all().small);

            if (isMobile) {
              return;
            }

            if (typeof site.product !== 'undefined' && typeof site.product.view !== 'undefined') {
              if (typeof site.product.view.equalRowHeight !== 'undefined') {
                site.product.view.equalRowHeight($product);
              }
              if (typeof site.product.view.brief !== 'undefined') {
                site.product.view.brief({
                  containerNode: this,
                  productData: productData
                });
              }
            }

            $('.qs_link', $product)
              .off()
              .on('click', function (event) {
                event.preventDefault();
                site.quickshop(productData);
              });
          };

          _qsInit();

          $(window).on(
            'resize',
            _.debounce(function () {
              _qsInit();
            }, 250)
          );
        });

        function addCustomFontColor() {
          var $productBriefMiscFlag = $module.find('.product-brief__misc-flag');
          var $header = $module.find('.product-brief__header');
          var $subHedaer = $module.find('.product-brief__sub-header');
          var $productBriefDesc1 = $module.find('.product-brief__desc1');
          var $price = $module.find('.product-brief__price');
          var $cta = $module.find('.cta');

          $productBriefMiscFlag.css('color', colorTheme);
          $header.css('color', colorTheme);
          $subHedaer.css('color', colorTheme);
          $productBriefDesc1.css('color', colorTheme);
          $price.css('color', colorTheme);
          if (ctaFontOverride) {
            $cta.addClass(ctaFontOverride);
          }
        }
        addCustomFontColor();
      });
    }
  };
})(jQuery);
